import * as React from "react"
import Layout from "../../../../components/layout"
import FinancialMarkets101Title
  from "../../../../components/pages/guides/training-guides/financial-markets-101/financial-markets-101-title"
import "./financial-markets-101.css"
import FinancialGuidelines
  from "../../../../components/pages/guides/training-guides/financial-markets-101/financial-guidelines"

const GuidesAcademyPage = () => (
  <Layout title="Financial Markets 101" description="Learn how to trade the financial markets with ease with our trading materials that take you from a beginner to an expert trader.">
    <FinancialMarkets101Title />
    <FinancialGuidelines />
  </Layout>
)

export default GuidesAcademyPage
