import React from "react"
import { Container } from "../../../../utils"
import { StaticImage } from "gatsby-plugin-image"
import ctl from "@netlify/classnames-template-literals"

const FinancialMarkets101Title = () => {
  return (
    <Container
      size='header'
      className={containerStyling}
    >
      <header className='md-max:mt-[30px]'>
        <h1 className={headerStyling}>
          Financial Markets 101
        </h1>
        <p className={textStyling}>
          Trading financial markets is one of the fastest <br className='desktop-breakpoint'/>
          wealth building vehicle in existence today. In this <br className='desktop-breakpoint'/>
          course, we’ll be teaching you how to take <br className='desktop-breakpoint'/>
          advantage of this.
        </p>
      </header>
      <StaticImage
        src="../../../../../assets/images/pages/guides/financial-markets.webp"
        alt="Financial Markets 101"
        className='max-w-[608px] w-full md-max:rounded-[6px]'
      />
    </Container>
  )
}

const containerStyling = ctl(
  `
    flex justify-between mt-[86px] mb-[136px] 
    md-max:flex-col-reverse md:gap-y-8 
    md-max:mt-[40px] md-max:mb-[50px]
  `
)

const headerStyling = ctl(
  `font-extrabold text-[32px] lg:text-[40px] mb-5 md-max:text-center`
)

const textStyling = ctl(
  `
    text-[16px] leading-[25px]
    md:text-[20px] md:leading-[30px] 
    md-max:text-center md-max:max-w-[322px] md-max:mx-auto
  `
)

export default FinancialMarkets101Title