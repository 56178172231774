import React from "react"
import Button from "../../../../utils/button"
import download from "../../../../../assets/icons/download.svg"
import play from "../../../../../assets/icons/play.svg"

const GuidesActions = ({ onPlay, hasDownloadPDF, hasPlayVideo }) => {
  return (
    <div className="max-w-[225px] w-full flex justify-between mx-auto md:mx-unset gap-x-[15px]">
      {
        hasDownloadPDF && (
          <Button
            label={
              <span className="inline-flex text-[10px] gap-3">
                Download Pdf
                <span>
                  <img src={download} alt="download" />
                </span>
              </span>
            }
            hasMargin={false}
            inGroup
          />
        )
      }
      {
        hasPlayVideo && (
          <Button
            label={
              <span className="inline-flex text-[10px] gap-3">
                Watch video
                <figure className="mt-px">
                  <img src={play} alt="download" />
                </figure>
              </span>
            }
            isButton
            onClick={onPlay}
            type="secondary"
            hasMargin={false}
            inGroup
          />
        )
      }
    </div>
  )
}

export default GuidesActions