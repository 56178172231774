import React, { useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container } from "../../../../utils"
import ctl from "@netlify/classnames-template-literals"
import GuidesActions from "./guides-actions"
import InPagePlayer from "../../../../utils/in-page-player"
import ComingSoonBadge from "../../../../coming-soon-badge"

const steps = [
  {
    textWrapperClass: "md:mt-[28px]",
    title: "Introduction to the financial markets",
    description: (
      <span>
        There are vast opportunities for profit in the financial markets, <br className="desktop-breakpoint" />
        get started here.
      </span>
    ),
    videoUrl: 'https://youtu.be/9irfGw2ahdU',
    hasDownloadPDF: true
  },
  {
    textWrapperClass: "md:mt-[35px]",
    title: "What are financial instruments?",
    description: (
      <span>
        They are simply assets that are traded in financial <br className="desktop-breakpoint" />
        markets in a long or short position.
      </span>
    ),
    videoUrl: 'https://youtu.be/0yFHFtCaXyc',
    hasDownloadPDF: true
  },
  {
    textWrapperClass: "md:mt-[38px]",
    title: "Trading terminologies you should know",
    description: (
      <span>
        These are terms used by traders to communicate in the financial <br className="desktop-breakpoint" />
        market. Want to sound like a pro? Learn these terminologies.
      </span>
    ),
    hasDownloadPDF: true
  },
  {
    textWrapperClass: "md:mt-[28px]",
    title: "How to use MT4",
    description: (
      <span>
        Explore opportunities in the financial <br className="desktop-breakpoint" />
        markets, trade with USD
      </span>
    ),
    videoUrl: 'https://youtu.be/vjR0Gx39ZgY',
    hasDownloadPDF: true
  },
  {
    textWrapperClass: "md:mt-[25px]",
    title: "How to use Eagletrade",
    description: (
      <span>
        Explore opportunities in the financial markets, <br className="desktop-breakpoint" />
        trade in your local currency.
      </span>
    ),
    comingSoon: true
  },
  {
    textWrapperClass: "md:mt-[24px]",
    title: "Risk Management",
    description: (
      <span>
        Trading financial instruments comes with some <br className="desktop-breakpoint" />
        level of risk, learn how to protect your capital.
      </span>
    ),
    hasDownloadPDF: true
  },
  {
    textWrapperClass: "md:mt-[18px]",
    title: (
      <>
        Strategies to find profitable <br className="desktop-breakpoint" /> trades.
      </>
    ),
    description: (
      <span>
        These strategies will help you use trading tools <br className="desktop-breakpoint" />
        to identify profitable trades.
      </span>
    ),
    hasDownloadPDF: true
  },
  {
    textWrapperClass: "md:mt-[16px]",
    title: (
      <>
        Creating a winning <br className="mobile-breakpoint" /> trading <br className="desktop-breakpoint" /> strategy
      </>
    ),
    description: (
      <span>
        A good trading plan is detailed, purposeful and <br className="desktop-breakpoint" /> even reflective.
      </span>
    ),
    hasDownloadPDF: true
  }
]

const GuideLine = ({ image, step }) => {
  const [showVideo, setShowVideo] = useState(false);

  const toggleShowVideo = () => setShowVideo(prevState => !prevState);

  return (
    <article
      className={ctl(
        `
          flex gap-x-[44px]
          md:w-[700px]
          lg:w-[967px]
          md-max:flex-col
          items-center
          md-max:w-full
          md-max:mx-auto
          md-max:gap-8
          relative
          md:py-[40px] md:px-[100px]
          py-[44px] px-[20px]
          border border-[2px] border-button-blue rounded-[20px]
          relative
        `
      )}
    >
      <InPagePlayer show={showVideo} videoUrl={step.videoUrl} onClose={toggleShowVideo}/>
      <GatsbyImage
        alt={step.title.toString()}
        image={image}
        className="max-w-[227px]"
      />
      <div>
        <h3 className={headerStyling}>
          <span>
            {step.title}
          </span>
          {
            step.comingSoon && (
              <ComingSoonBadge className='md-max:!text-base md-max:!leading-[23px] absolute top-0 translate-y-[-50%] translate-x-[-50%] left-[50%]'/>
            )
          }
        </h3>
        <p className={descriptionStyling}>
          {step.description}
        </p>
        <GuidesActions hasDownloadPDF={step.hasDownloadPDF} hasPlayVideo={!!step.videoUrl} onPlay={toggleShowVideo}/>
      </div>
    </article>
  )
}

const FinancialGuidelines = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter:{relativeDirectory:{eq:"pages/guides/rows"}}){
        nodes {
          name
          childImageSharp{
            gatsbyImageData(width: 228)
          }
        }
      }
    }
  `)

  const images = useMemo(() => {
    const images = {}

    data.allFile.nodes.forEach(node => {
      images[node.name] = getImage(node)
    })

    return images
  }, [data])

  return (
    <Container
      size="header"
      className="grid gap-y-[80px] 1.5xl-max:px-10"
    >
      {
        steps.map((step, index) => (
          <GuideLine
            step={step}
            key={index}
            image={images[index + 1]}
          />
        ))
      }
    </Container>
  )
}

const headerStyling = ctl(
  `
    text-[26px] leading-[35px] font-extrabold mb-4 md-max:text-center md-max:justify-center flex items-center gap-x-3
  `
)

const descriptionStyling = ctl(`
  mt-0 text-base leading-[1.5] mb-4 md-max:text-center md-max:mb-6
`)

export default FinancialGuidelines